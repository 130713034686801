import React from "react";

import Container from "react-bootstrap/Container";

import { today } from "../../utilities/utility";
import { EXTERNAL_URL, DISCLAIMER } from "../../utilities/constants";

const LayoutFooter = () => (
  <footer className="layout-footer">
    <Container>
      <a href={EXTERNAL_URL.MARKETING_SITE} target="_blank" rel="noreferrer">
        <div className="logo-icon"></div>
      </a>
      <div className="copyright-text">
        Copyright {today("YYYY")} Red Rock Secured. All rights reserved
      </div>
      <div className="disclaimer-text">
        <span>Disclaimer:</span> {DISCLAIMER}
      </div>
      <ul className="footer-nav">
        <li>
          <a
            href={EXTERNAL_URL.TERMS_OF_SERVICE}
            target="_blank"
            rel="noreferrer"
          >
            terms of service
          </a>
        </li>
        <li>
          <a
            href={EXTERNAL_URL.PRIVACY_POLICY}
            target="_blank"
            rel="noreferrer"
          >
            privacy policy
          </a>
        </li>
      </ul>
    </Container>
  </footer>
);

export default LayoutFooter;
