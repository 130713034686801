import React from "react";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";

import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faTimesCircle,
  faBars
} from "@fortawesome/free-solid-svg-icons";

import { URL } from "../../utilities/constants";
import { logoutAction } from "../../utilities/utility";

const LayoutHeader = () => {
  const history = useHistory();

  const logoutUser = () => {
    logoutAction("logout");
    history.push(URL.LOGIN);
  };

  return (
    <header className="layout-header">
      <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav">
            <FontAwesomeIcon icon={faBars} />
          </Navbar.Toggle>
          <Navbar.Brand as={Link} to={URL.HOME} className="logo"></Navbar.Brand>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Container>
              <Nav className="ms-auto">
                <Nav.Link href="#home" className="d-none">
                  <FontAwesomeIcon icon={faUser} />
                  My Account
                </Nav.Link>
                <Nav.Link onClick={logoutUser} className="logout-button">
                  <FontAwesomeIcon icon={faTimesCircle} />
                  Logout
                </Nav.Link>
              </Nav>
            </Container>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default LayoutHeader;
