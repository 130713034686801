import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import _ from "lodash";
import Accordion from "react-bootstrap/Accordion";
import Table from "react-bootstrap/Table";

import Error from "../../components/Error";
import Loading from "../../components/Loading";
import Typography from "../../components/Typography";
import AssetFilter from "../../components/Assets/AssetFilter";

import API from "../../utilities/api";
import { IAssets, ITransactions } from "../../utilities/interface";
import { formatCurrency, formatDate } from "../../utilities/utility";

import { setSessionExpired } from "../../actions";

import "./style.scss";

const Assets = () => {
  const dispatch = useDispatch();

  const [selectedAssetType, setSelectedAssetType] = useState<string>("All");
  const [assets, setAssets] = useState<Array<IAssets> | null>(null);
  const [transactions, setTransactions] = useState<Array<ITransactions> | null>(
    null
  );
  const [error, setError] = useState<string>("");
  const [currentAccordionItem, setCurrentAccordionItem] = useState<
    number | null
  >(null);
  const [currentTransactionAccordionItem, setCurrentTransactionAccordionItem] =
    useState<number | null>(null);

  const fetchAssets = async () => {
    try {
      const result = await API.get("/Assets");
      setAssets(result.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(setSessionExpired(true));
        } else if (error.response.status === 500) {
          setError("Error in Fetching Assets");
        }
      }
      console.error(error);
    }
  };

  const fetchTransactions = async () => {
    try {
      const result = await API.get("/Transactions");
      setTransactions(result.data);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(setSessionExpired(true));
        } else if (error.response.status === 500) {
          setError("Error in Fetching Assets");
        }
      }
      console.error(error);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchAssets();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      await fetchTransactions();
    })();
  }, []);

  if (assets === null) {
    if (!error) {
      return <Loading text="Loading Assets..." />;
    }

    return <Error />;
  }

  const renderAssetFilter = () => {
    return (
      <div className="asset-filter-container">
        <Typography variant="h5">Choose your asset below to review</Typography>
        <AssetFilter
          selectedAssetType={selectedAssetType}
          setSelectedAssetType={setSelectedAssetType}
        />
      </div>
    );
  };

  const renderAccordionContent = (asset: IAssets) => {
    return (
      <div className="accordion-content">
        <Table>
          <tbody>
            <tr>
              <td>Amount Purchased</td>
              <td>{asset.amountPurchased}</td>
            </tr>
            <tr>
              <td>Total Weight (Ounces)</td>
              <td>{asset.totalWeight}</td>
            </tr>
          <tr>
            <td>Melt Value</td>
            <td>{formatCurrency(asset.meltValue)}</td>
          </tr>
            <tr>
              <td>Current Value</td>
              <td>{formatCurrency(asset.currentValue)}</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  const renderAssetAccordion = () => {
    let selectedAssets = assets;
    if (selectedAssetType !== "All") {
      selectedAssets = assets.filter((a) => a.metal === selectedAssetType);
    }

    if (!selectedAssets.length) {
      return <div className="no-data alert alert-warning">No Asset Found.</div>;
    }

    return (
      <Accordion className="assets-accordion">
        {selectedAssets.map((a: IAssets, i: number) => (
          <div className="accordion-item" key={i}>
            <Accordion.Toggle
              eventKey={`${i}`}
              className={`accordion-title${
                currentAccordionItem === i ? " active" : ""
              }`}
              onClick={() =>
                setCurrentAccordionItem(currentAccordionItem === i ? null : i)
              }
            >
              <span
                className={`asset-type-icon ${_.snakeCase(a.metal)}-icon`}
              ></span>
              <div className="accordion-title-text">{a.title}</div>
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${i}`}>
              {renderAccordionContent(a)}
            </Accordion.Collapse>
          </div>
        ))}
      </Accordion>
    );
  };

  const renderAssetTransactions = () => {
    if (!transactions) {
      return (
        <div className="no-data alert alert-info">Loading transactions...</div>
      );
    } else if (!transactions.length) {
      return (
        <div className="no-data alert alert-warning">No Transaction Found.</div>
      );
    }

    return (
      <div className="transactions-wrapper">
        <Table striped bordered className="transactions-table">
          <thead>
          <tr>
            <th className="asset-name">Asset Name</th>
            <th className="order-date">Order/Sell Date</th>
              <th className="amount-purchased">Amt. Purchased</th>
            <th className="total-weight">Total Weight</th>
            <th className="price">Original Acquisition Cost</th>
            <th className="price">Sale Price</th>
          </tr>
          </thead>
          <tbody>
            {transactions.map((a: ITransactions, i: number) => {
              return (
                <tr key={i}>
                  <td className="asset-name">{a.title}</td>
                  <td className="order-date">{formatDate(a.orderDate)}</td>
                  <td className="amount-purchased">{a.amountPurchased}</td>
                  <td className="total-weight">{a.totalWeight} oz</td>
                  <td className="price">{a.purchaseValue >= 0 ? formatCurrency(a.purchaseValue) : ""}</td>
                  <td className="price">{a.purchaseValue < 0 ? formatCurrency(a.purchaseValue*-1) : ""}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Accordion defaultActiveKey="0" className="transactions-accordion">
          {transactions.map((a: ITransactions, i: number) => (
            <div className="accordion-item" key={i}>
              <Accordion.Toggle
                eventKey={`${i}`}
                className={`accordion-title${
                  currentTransactionAccordionItem === i ? " active" : ""
                }`}
                onClick={() =>
                  setCurrentTransactionAccordionItem(
                    currentTransactionAccordionItem === i ? null : i
                  )
                }
              >
                <div className="accordion-title-text">{a.title}</div>
                <div className="accordion-title-details">
                  {formatDate(a.orderDate)}
                  <br />
                  {formatCurrency(a.currentValue)}
                </div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={`${i}`}>
                <div className="accordion-content">
                  <Table>
                    <tbody>
                      <tr>
                        <th>Asset Name</th>
                        <td>{a.title}</td>
                      </tr>
                      <tr>
                        <th>Order/Sell Date</th>
                        <td>{formatDate(a.orderDate)}</td>
                      </tr>
                      <tr>
                        <th>Amount Purchased</th>
                        <td>{formatCurrency(a.amountPurchased)}</td>
                      </tr>
                      <tr>
                        <th>Total Weight</th>
                        <td>{a.totalWeight} oz</td>
                      </tr>
                      <tr>
                        <th>Original Acquisition Cost</th>
                        <td>{formatCurrency(a.purchaseValue)}</td>
                      </tr>
                      <tr>
                        <th>Sale Price</th>
                        <td>{formatCurrency(a.currentValue)}</td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </div>
    );
  };

  return (
    <div className="assets-page">
      <Typography variant="h1" className="page-title">
        Assets
      </Typography>
      {renderAssetFilter()}
      {renderAssetAccordion()}

      <Typography variant="h2" className="page-title">
        Transactions
      </Typography>
      {renderAssetTransactions()}
    </div>
  );
};

export default Assets;
