import { CONFIG_OPTIONS } from "../config";
import * as Yup from "yup";

const local_api_prefix = process.env.REACT_APP_API_BASE_URL;
const dev_api_prefix = process.env.REACT_APP_SERVER_API_BASE_URL;

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

export const DEFUALT_DATE_FORMAT = "M/D/YY";
export const DEFUALT_TIME_FORMAT = "h:mm a";
export const API_PREFIX = CONFIG_OPTIONS.ApiPrefixUseDev
  ? dev_api_prefix
  : local_api_prefix;

export const MY_STORAGE = window.sessionStorage;

// Urls
export const URL = {
  HOME: "/",
  NEWS: "/news",
  ASSETS: "/assets",
  LOGIN: "/login",
  ACCOUNT: "/account",
  LOGIN_INVITATION: "/register",
  RESET_PASSWORD: "/reset-password",
  CREATE_PASSWORD: "/register",
  FORGOT_PASSWORD: "/forgot-password",
  MULTI_FACTOR_AUTH: "/multi-factor-auth",
};

export const ADMIN_URL = {
  HOME: "/admin",
  LOGIN: "/admin/login",
};

export const EXTERNAL_URL = {
  MARKETING_SITE: "https://redrocksecured.com/",
  TERMS_OF_SERVICE: "https://redrocksecured.com/terms-of-service/",
  PRIVACY_POLICY: "https://redrocksecured.com/red-rock-secured-privacy-policy/",
};

export const CTA_PHONE = "844-824-5051";

export const DISCLAIMER =
  '"Retail Value" as shown is a representation of current retail sales price with Red Rock Secured. This is not an appraisal or estimate of a buyback price with Red Rock Secured which is based on market conditions, inventory levels, and other factors.';

export const PHONE_NUMBER_MASK = [
  /[1-9]/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
];

export const FORM_VALIDATION = {
  EMAIL: Yup.string()
    .email("Invalid email")
    .max(50, "Email should not exceed 50 characters")
    .required("Email is required"),
  PASSWORD: Yup.string()
    .required("Password is required")
    .max(50, "Password should not exceed 50 characters"),
  CREATE_PASSWORD: Yup.string()
    .required("Password is required")
    .min(8, "Password is too short - should be 8 chars minimum.")
    .max(50, "Password should not exceed 50 characters")
    .matches(
      /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
      "Must Contain  One Uppercase, One Lowercase, One Number & One Special Case Character"
    ),
  CONFIRM_PASSWORD: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
  CODE: Yup.string().required("Code is required"),
  FNAME: Yup.string()
    .required("First name is required")
    .max(50, "First name should not exceed 50 characters"),
  LNAME: Yup.string()
    .required("Last name is required")
    .max(50, "Last name should not exceed 50 characters"),
  PHONE: Yup.string()
    .required("Phone number is required")
    .matches(phoneRegExp, "Phone number is not valid"),
};
