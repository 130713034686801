import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { findIndexFromJsonArray, getCurrentUser } from "../../utilities/utility"

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneAlt, faSyncAlt } from "@fortawesome/free-solid-svg-icons";

import Error from "../../components/Error";
import Loading from "../../components/Loading";
import Typography from "../../components/Typography";
import SummaryCard from "../../components/Portfolio/SummaryCard";
import SummaryGraph from "../../components/Portfolio/SummaryGraph";
import AssetsDetailsCard from "../../components/Portfolio/AssetsDetailsCard";

import API from "../../utilities/api";
import { IPortfolio, IPortfolioAssetsDetails } from "../../utilities/interface";
import { CTA_PHONE } from "../../utilities/constants";

import { setSessionExpired } from "../../actions";

import "./style.scss";

const Home = () => {
  const dispatch = useDispatch();

  const [portfolio, setPortfolio] = useState<IPortfolio | null>(null);
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);
  const [error, setError] = useState<string>("");


  const refreshMarketPrice = async () => {
    setIsRefreshing(true)
    await fetchPortfolioData("?IsRefresh=true");
    setIsRefreshing(false)
  };

  const fetchPortfolioData = async (action = "") => {
    try {
      const result = await API.get(`/Portfolio${action ? action : ''}`);
      if (result.status === 200) {
        setPortfolio(result.data);
      } else {
        throw "error";
      }
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(setSessionExpired(true));
        } else if (error.response.status === 500) {
          setError("Error in Fetching Portfolio");
        }
      }
      console.error(error);
    }
  };

  useEffect(() => {
      (async () => {
        await fetchPortfolioData();
      })();
    },
    []);

  const user = getCurrentUser();

  if (portfolio === null) {
    if (!error) {
      return <Loading text="Loading Portfolio..." />;
    }

    return <Error />;
  }

  const renderSummarySection = () => {
    const { total, metalsOwn } = portfolio;

    return (
      <div className="summary-section">
        <Row>
          <Col lg={6}>
            <Row>
              <Col xs={6}>
                <SummaryCard
                  label="TOTAL ACQUISITION COST"
                  value={total.totalAssetsCost}/>
              </Col>
              <Col xs={6}>
                <SummaryCard
                  label="CURRENT ASSESSED VALUE"
                  value={total.totalMeltValue}
                />
              </Col>
              <Col xs={12}>
                <SummaryCard
                  label="CURRENT RETAIL VALUE"
                  value={total.totalAssetsValue}
                  className="summary-sum"
                />
              </Col>
            </Row>
          </Col>
          <Col lg={12} className="order-lg-2">
            <div className="summary-action">
              <a className="btn btn-primary" href={`tel:${CTA_PHONE}`}>
                <span>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </span>
                Call to Buy More
              </a>
            </div>
          </Col>
          <Col lg={6} className="order-lg-1">
            <SummaryGraph data={metalsOwn} />
          </Col>
        </Row>
      </div>
    );
  };

  const populatedMissingAssets = (allAssets: Array<IPortfolioAssetsDetails>) => {
    let toConcat:Array<IPortfolioAssetsDetails> = [];
    const mapTargets:string[] = ["Gold", "Silver", "Platinum", "Palladium"];

    mapTargets.map((currentTarget: string) => {
      if (findIndexFromJsonArray(allAssets,"type",currentTarget) === -1) {
        toConcat.push({
          type: currentTarget,
          currentValue: 0,
          acquisitionCost: 0,
          quantity: 0,
          ounces: 0
        })
      }
    });

    return allAssets.concat(toConcat).sort( function(a,b) { 
      if ( a.type == b.type ) return 0;
      return mapTargets.indexOf( a.type ) - mapTargets.indexOf( b.type ) ;
    });
  };

  const renderAssetsDetails = () => {
    const { assetsDetails } = portfolio;

    return (
      <div className="assests-details-section">
        <span className="assests-details-title">ASSETS DETAILS</span>
        <div className="assests-details-wrapper">
          {populatedMissingAssets(assetsDetails).map(
            (a: IPortfolioAssetsDetails) => (
              <AssetsDetailsCard
                key={a.type}
                title={a.type}
                currentValue={a.currentValue}
                acquisitionCost={a.acquisitionCost}
                quantity={a.quantity}
                ounces={a.ounces}
                className="assests-details-card"
              />
            )
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="home-page">
      <Typography variant="h1" className="page-title">
        Welcome, {user?.firstName}
      </Typography>
      <Button
        variant="link"
        size="sm"
        className="btn-refresh-price"
        onClick={refreshMarketPrice}
      >
        {isRefreshing ? 'Refreshing' : 'Refresh'}
        <FontAwesomeIcon icon={faSyncAlt} pulse={isRefreshing} />
      </Button>

      {renderSummarySection()}
      {renderAssetsDetails()}
    </div>
  );
};

export default Home;
