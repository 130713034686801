import React from "react";
import cn from "classnames";

import "./style.scss";

type Variant = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | string;

type Props = {
  variant: Variant;
  color?: string;
  className?: string;
  children: React.ReactNode;
};

const variantsMapping: any = {
  h1: "h1",
  h2: "h2",
  h3: "h3",
  h4: "h4",
  h5: "h5",
  h6: "h6",
  p: "p",
};

const Typography = ({
  variant,
  color,
  className,
  children,
  ...props
}: Props) => {
  const Component = variant ? variantsMapping[variant] : "p";

  return (
    <Component
      className={cn({
        [`typography--variant-${variant}`]: variant,
        [`typography--color-${color}`]: color,
        [`${className}`]: className,
      })}
      {...props}
    >
      {children}
    </Component>
  );
};

export default Typography;
