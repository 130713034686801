import React, { MouseEventHandler } from "react";
import cn from "classnames";
import Modal from "react-bootstrap/Modal";

import "./vertically_centered.scss";

type Props = {
  show: boolean;
  modelTile?: string;
  size?: "sm" | "lg" | "xl";
  className?: string;
  bodyClassName?: string;
  dismissible?: boolean;
  backdrop?: string;
  keyboard?: boolean;
  onHide?: MouseEventHandler;
  children: React.ReactNode;
};

const VerticallyCenteredModal = (props: Props) => {
  const { className } = props;
  const modalProps = {...props}
  delete modalProps.modelTile

  return (
    <Modal
      {...modalProps}
      size={modalProps.size}
      className={cn({
        [`theme-modal`]: "theme-modal",
        [`${className}`]: className,
      })}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        {props.modelTile ? (
          <Modal.Title id="contained-modal-title-vcenter">
            {props.modelTile}
          </Modal.Title>
        ) : (
          <></>
        )}
      </Modal.Header>
      <Modal.Body className={props.bodyClassName}>{props.children}</Modal.Body>
    </Modal>
  );
};

export default VerticallyCenteredModal;
