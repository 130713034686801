import moment from "moment";

import {
  DEFUALT_DATE_FORMAT,
  DEFUALT_TIME_FORMAT,
  MY_STORAGE,
} from "./constants";
import { setAuth, removeAuth, removeAdminAuth } from "../workers/index";

import { ILoggedInUser, IAuth } from "./interface";

export function loginAction(authResult: IAuth) {
  setInStorage("auth", JSON.stringify(authResult));
  setAuth(authResult);
}

export function getMFAAuth(): IAuth | null {
  let authResult = getFromStorage("auth");
  if (authResult == null) return null;

  return JSON.parse(authResult);
}

export function authenticationAction(user: ILoggedInUser, token: string) {
  setInStorage("user", JSON.stringify(user));
  setInStorage("userType", "customer");
  let auth = getMFAAuth();
  if (auth != null) {
    auth.access_token = token;
    loginAction(auth);
  }
  removeFromStorage("logoutStatus");
}

export function getCurrentUser(): ILoggedInUser | null {
  const userType = getFromStorage("userType");
  const user = getFromStorage("user");
  if (userType === "customer" && user) {
    return JSON.parse(user);
  }

  return null;
}

export function logoutAction(status?: string | any) {
  removeFromStorage("userType");
  removeFromStorage("user");
  removeFromStorage("auth");
  removeAuth();
  setInStorage("logoutStatus", status);
}

export function getCurrentAdmin() {
  const user = getFromStorage("admin");
  if (user) {
    return JSON.parse(user);
  }

  return null;
}

export function logoutAdminAction(status?: string | any) {
  removeFromStorage("userType");
  removeFromStorage("user");
  removeFromStorage("auth");
  removeAdminAuth();
  setInStorage("adminLogoutStatus", status);
}

export const setInStorage = (key: string, value: string) => {
  MY_STORAGE.setItem(key, value);
};

export const getFromStorage = (key: string) => {
  return MY_STORAGE.getItem(key);
};

export const removeFromStorage = (key: string) => {
  return MY_STORAGE.removeItem(key);
};

export const getLogoutMessage = (status?: string) => {
  let message;
  switch (status) {
    case "unauthorized":
      message = "";
      break;
    case "session_expired":
      message = "Your user session has expired. Please log in again.";
      break;
    case "logout":
      message = "";
      break;
    default:
      message = "";
  }

  return message;
};

export const deepCopy = (something: any) => {
  return JSON.parse(JSON.stringify(something));
};

// returns true if the string is empty
export const stringIsEmpty = (value: string) => {
  return !value || value.length === 0 || !value.trim();
};

export const objectIsEmpty = (obj: Object) => {
  return Object.keys(obj).length === 0;
};

// deletes any duplicates in teh array
export const removeDuplicatesFromArray = (someArray: Array<any>) => {
  return someArray.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

export const removeEmptyStringsFromArray = (someArray: Array<any>) => {
  return someArray.filter(function (value: string) {
    return !(!value || value.length === 0 || !value.trim());
  });
};

export const formatDate = (
  dateTime: string,
  format: string = DEFUALT_DATE_FORMAT
) => {
  if (!dateTime) {
    return "";
  }
  return moment(dateTime).format(format);
};

export const getAgoFromDateTime = (dateTime: string) => {
  if (!dateTime) {
    return "";
  }

  const now = new Date();
  const nowMoment = moment(now);
  const pastMoment = moment(dateTime);
  return pastMoment.from(nowMoment);
};

export const formatTime = (
  dateTime: string,
  format: string = DEFUALT_TIME_FORMAT
) => {
  if (!dateTime) {
    return "";
  }
  return moment(dateTime).format(format);
};

export const today = (format: string = DEFUALT_DATE_FORMAT) => {
  return moment().format(format);
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return phoneNumberString;
};

export const formatCurrency = (number: number | string, currency = "USD") => {
  const locale = navigator.language || "en-US";
  if (!number || isNaN(Number(number))) number = 0;
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(Number(number));
};

// returns value at first occurrence of a given key from the query string
export const getQueryStringValue = (key: string) => {
  key = key.replace(/[*+?^$.[\]{}()|\\/]/g, "\\$&"); // escape RegEx meta chars
  var match = window.location.search.match(
    new RegExp("[?&]" + key + "=([^&]+)(&|$)")
  );
  return match && decodeURIComponent(match[1].replace(/\+/g, " "));
};

export const getChartSize = () => {
  let screenWidth = window.innerWidth;
  let width = 0;
  let height = 0;
  if (screenWidth < 700) {
    if (screenWidth < 365) {
      width = screenWidth - 75;
      height = screenWidth - 75;
    } else {
      width = 150;
      height = 150;
    }
  } else {
    width = 200;
    height = 200;
  }
  return {
    width: width,
    height: height,
  };
};

export const getColorByMetalType = (type: string) => {
  switch (type) {
    case "Gold":
      return "#E2B671";
    case "Silver":
      return "#DDDDDD";
    case "Platinum":
      return "#BFBFBF";
    case "Palladium":
      return "#ABADB7";
    default:
      return "#FEFEFE";
  }
};

/*
array_of_json: array to look through
json_key: key to test against the success_value
success_value: value we're trying to find

-- returns the item at the index that the specific success value was found
*/
export const findEntryFromJsonArray = (
  array_of_json: any,
  json_key: any,
  success_value: any
) => {
  var array = array_of_json;
  var result = null;
  array = array.filter(function (element: any) {
    return element[json_key] === success_value;
  });
  if (array.length) {
    result = array[0];
  }
  return result;
};

/**
 * array_of_json: array to look through
 *
 * json_key: key to test against the success_value
 *
 * success_value: value we're trying to find
 *
 * -- returns index of matching entry or -1 if not found
 */
export const findIndexFromJsonArray = (
  array_of_json: any,
  json_key: any,
  success_value: any
) => {
  var array = array_of_json;
  var target_index = -1;
  array.filter(function (element: any, index: any) {
    if (element[json_key] === success_value) {
      target_index = index;
    }
    return element[json_key] === success_value;
  });
  return target_index;
};
