import React, { useState, useEffect } from "react";
import _ from "lodash";

import parse from "html-react-parser";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faSpinner } from "@fortawesome/free-solid-svg-icons";

import Error from "../../components/Error";
import Loading from "../../components/Loading";
import Typography from "../../components/Typography";

import API from "../../utilities/api";
import { INews } from "../../utilities/interface";
import { getAgoFromDateTime } from "../../utilities/utility";

import "./style.scss";

const News = () => {
  const [news, setNews] = useState<Array<INews> | null>(null);
  const [error, setError] = useState<string>("");
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPage] = useState<number>(0);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const fetchNews = async () => {
    try {
      const result = await API.get(
        `https://redrocksecured.com/wp-json/wp/v2/posts?categories=4460,4461&page=${currentPage}`
      );
      if (currentPage === 1) {
        setNews(result.data);
        setTotalPage(result.headers["x-wp-totalpages"]);
      } else {
        news?.push(...result.data);
        setNews(news);
        setLoadingMore(false);
      }
    } catch (err) {
      console.error(err);
      setError("Error in Fetching Assets");
    }
  };

  useEffect(() => {
    (async () => {
      await fetchNews();
    })();
  }, [currentPage]);

  if (news === null) {
    if (!error) {
      return <Loading text="Loading News..." />;
    }

    return <Error />;
  }

  const renderNews = () => {
    return news.map((n) => {
      return (
        <div className="news-wrapper" key={n.id}>
          <div className="news-image">
            <img
              src={_.head(n.featured_image_urls_v2?.thumbnail)}
              className="img-thumbnail"
            />
            <img src={_.head(n.featured_image_urls_v2?.full)} className="img-full" />
          </div>
          <div className="news-content">
            <div className="news-date">{getAgoFromDateTime(n.date)}</div>
            <Typography variant="h4">{parse(n.title.rendered)}</Typography>
            <div className="news-content-text">
              {parse(n.excerpt.rendered)}
              <a href={n.link} target="_blank" rel="noreferrer">
                Read More
              </a>
            </div>
          </div>
        </div>
      );
    });
  };

  const loadMore = () => {
    setCurrentPage(currentPage + 1);
    setLoadingMore(true);
  };

  return (
    <div className="news-page">
      <Typography variant="h1" className="page-title">
        News
      </Typography>

      <div className="news-container">{renderNews()}</div>

      <div className="load-more-action">
        {loadingMore ? (
          <div className="btn btn-more">
            <FontAwesomeIcon icon={faSpinner} pulse />
            Loading more News...
          </div>
        ) : totalPages && currentPage < totalPages ? (
          <Button variant="link" onClick={loadMore}>
            Load More News
            <FontAwesomeIcon icon={faAngleDown} />
          </Button>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default News;
