import React from "react";

import _ from "lodash";
import Dropdown from "react-bootstrap/Dropdown";

import "./asset-filter.scss";

type Props = {
  selectedAssetType: string;
  setSelectedAssetType: any;
};

const AssetFilter = ({ selectedAssetType, setSelectedAssetType }: Props) => {
  const assetsTypes = ["All", "Silver", "Gold", "Platinum", "Palladium"];

  const renderFilterOptions = (assetType: string) => {
    if (assetType === "All") {
      return (
        <>
          <span className="asset-type-all-icons">
            {assetsTypes.map((assetType: string) => {
              if (assetType !== "All") {
                return (
                  <span
                    key={assetType}
                    className={`asset-type-icon ${_.snakeCase(assetType)}-icon`}
                  ></span>
                );
              }
            })}
          </span>
          Show All your Assets
        </>
      );
    }

    return (
      <>
        <span
          className={`asset-type-icon ${_.snakeCase(assetType)}-icon`}
        ></span>
        Show {assetType} Assets
      </>
    );
  };

  return (
    <Dropdown className="asset-filter-dropdown">
      <Dropdown.Toggle id="dropdown-autoclose-true" block>
        {renderFilterOptions(selectedAssetType)}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {assetsTypes.map((assetType: string) => {
          if (assetType !== selectedAssetType) {
            return (
              <Dropdown.Item
                as="button"
                key={assetType}
                onClick={() => setSelectedAssetType(assetType)}
              >
                {renderFilterOptions(assetType)}
              </Dropdown.Item>
            );
          }
        })}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default AssetFilter;
