import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { URL } from "../../utilities/constants";

import "./index.scss";

type Props = {
  bigText?: string;
  smallText?: string;
  showReturnHomeButton?: boolean;
};

const Error = ({ bigText, smallText, showReturnHomeButton = false }: Props) => (
  <div className="error-message" role="error">
    <FontAwesomeIcon icon={faExclamationTriangle} />
    <h1 className="big-text">{bigText || "An unexpected error has occurred"}</h1>
    <p className="small-text">{smallText || "If you continue to experience problems, please contact customer support at 844-824-5051"}</p>
    {showReturnHomeButton && (
      <Link to={URL.HOME} className="btn btn-primary">
        Return Home
      </Link>
    )}
  </div>
);

export default Error;
