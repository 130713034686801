import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faSpinner,
  faTimesCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import MaskedInput from "react-text-mask";

import { Alert, Button } from "react-bootstrap";

import Typography from "../../../components/Typography";
import VerticallyCenteredModal from "../../../components/Modal/vertically_centered";

import API from "../../../utilities/api";
import {
  FORM_VALIDATION,
  PHONE_NUMBER_MASK,
} from "../../../utilities/constants";
import {
  IAdminAccountInformation,
  IAdminAccountInformationFormData,
} from "../../../utilities/interface";

import "./style.scss";

const ClientManagement = () => {
  const [selectedCustomer, setSelectedCustomer] =
    useState<IAdminAccountInformation | null>(null);
  const [customerInitialValue, setCustomerInitialValue] =
    useState<IAdminAccountInformationFormData | null>(null);
  const [customerId, setCustomerId] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [isActivating, setIsActivating] = useState<boolean>(false);
  const [isInviting, setIsInviting] = useState<boolean>(false);
  const [isImporting, setIsImporting] = useState<boolean>(false);
  const [showEditView, setShowEditView] = useState<boolean>(false);

  const [editAccountError, setEditAccountError] = useState<string>("");

  const AccountEditSchema = Yup.object().shape({
    firstName: FORM_VALIDATION.FNAME,
    lastName: FORM_VALIDATION.LNAME,
    email: FORM_VALIDATION.EMAIL,
    phone: FORM_VALIDATION.PHONE,
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    setError("");
    setCustomerId("");
    setSelectedCustomer(null);
    setIsSearching(true);
    const form: any = e.target;
    const value = form.search.value;
    try {
      const response = await API.get(`/externalAccounts/${value}`);
      setSelectedCustomer(response.data);
      setIsSearching(false);
      setCustomerId(form.search.value);
    } catch (error) {
      if (error.response) {
        if (error.response.status === 401) {
          setError("Customer not found.");
        } else {
          setError("Error in Fetching Customer.");
        }
      }
      setIsSearching(false);
    }
    form.search.value = "";
  };

  const activateCustomer = async () => {
    if (window.confirm("Are you sure you want to activate this customer?")) {
      setIsActivating(true);

      try {
        const response = await API.post(
          `/externalAccounts/${customerId}/activate`
        );
        setSelectedCustomer(response.data);
        setSuccess("User Activated Successfully");
      } catch (error) {
        if (error.response) {
          setError("Error in Activating User.");
        }
      }
      setIsActivating(false);
    }
  };

  const resendInvite = async (userId: string) => {
    if (window.confirm("Are you sure you want to resend invite?")) {
      setIsInviting(true);

      try {
        await API.post(`/externalAccounts/${userId}/invite`);
        setSuccess("Invitation Sent to User");
      } catch (error) {
        if (error.response) {
          setError("Error in Inviting User.");
        }
      }
      setIsInviting(false);
    }
  };

  const importTransaction = async (userId: string) => {
    if (window.confirm("Are you sure you want to import transactions?")) {
      setIsImporting(true);

      try {
        await API.post(`/externalAccounts/${userId}/import`);
        setSuccess("Transactions have been imported successfully.");
      } catch (error) {
        if (error.response) {
          setError("Error in importing transactions.");
        }
      }
      setIsImporting(false);
    }
  };

  const showEditAccount = (customer: IAdminAccountInformation) => {
    setShowEditView(true);

    const customerInitVal = {
      userId: customer.leadId,
      firstName: customer.firstName,
      lastName: customer.lastName,
      phone: customer.phoneNumber,
      email: customer.email,
    };
    setCustomerInitialValue(customerInitVal);
  };

  const handleUpdateAccount = async (
    userId: string,
    values: IAdminAccountInformationFormData
  ) => {
    setEditAccountError("");
    try {
      await API.put(`/Users/${userId}`, values);
      if (selectedCustomer) {
        selectedCustomer.firstName = values.firstName;
        selectedCustomer.lastName = values.lastName;
        selectedCustomer.email = values.email;
        selectedCustomer.phoneNumber = values.phone;
        console.log('>>> response', selectedCustomer)
  
        setShowEditView(false);
        setSelectedCustomer(selectedCustomer);
      }

    } catch (error) {
      console.error(error);
      setEditAccountError("Error editing account");
    }
  };

  const renderCustomerCard = () => {
    if (!selectedCustomer) {
      return <></>;
    }

    return (
      <div className="customer-card">
        <div className="customer-data">
          <h4>
            {selectedCustomer.firstName} {selectedCustomer.lastName}
          </h4>
          <p>
            <span>Phone:</span> {selectedCustomer.phoneNumber}
          </p>
          <p>
            <span>Email:</span> {selectedCustomer.email}
          </p>
        </div>
        <div className="customer-action">
          {selectedCustomer.status === "Not invited" && (
            <Button
              variant="secondary"
              onClick={() => activateCustomer()}
              disabled={isActivating}
            >
              {isActivating ? (
                <>
                  <FontAwesomeIcon icon={faSpinner} pulse />
                  Activating
                </>
              ) : (
                "Activate Customer"
              )}
            </Button>
          )}
          {selectedCustomer.status === "Invited not accepted" && (
            <>
              <Button
                variant="secondary"
                onClick={() => resendInvite(selectedCustomer.leadId)}
                disabled={isInviting}
              >
                {isInviting ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} pulse />
                    Inviting
                  </>
                ) : (
                  "Resend Invite"
                )}
              </Button>
              <Button
                variant="secondary"
                onClick={() => importTransaction(selectedCustomer.leadId)}
                disabled={isImporting}
              >
                {isImporting ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} pulse />
                    Importing
                  </>
                ) : (
                  "Import Transactions"
                )}
              </Button>
              <Button
                variant="light"
                onClick={() => showEditAccount(selectedCustomer)}
              >
                Edit Account
              </Button>
            </>
          )}
          {selectedCustomer.status === "Invited and accepted" && (
            <>
              <Button
                variant="secondary"
                onClick={() => importTransaction(selectedCustomer.leadId)}
                disabled={isImporting}
              >
                {isImporting ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} pulse />
                    Importing
                  </>
                ) : (
                  "Import Transactions"
                )}
              </Button>
              <Button
              variant="light"
              onClick={() => showEditAccount(selectedCustomer)}
            >
                Edit Account
            </Button>
            </>
          )}
        </div>
      </div>
    );
  };

  const renderCustomerEdit = () => {
    if (!selectedCustomer || !customerInitialValue) {
      return <></>;
    }

    return (
      <div className="customer-card customer-edit-card">
        {editAccountError && (
          <Alert
            variant="danger"
            onClose={() => {
              setEditAccountError("");
            }}
            dismissible
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{editAccountError}</span>
          </Alert>
        )}
        <Formik
          initialValues={customerInitialValue}
          validationSchema={AccountEditSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await handleUpdateAccount(selectedCustomer.leadId, values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleChange, errors, touched }) => (
            <Form className="customer-edit-form">
              <div className="customer-edit-data">
                <div
                  className={`form-group ${
                    errors.firstName && touched.firstName
                      ? "has-validation"
                      : ""
                  }`}
                >
                  <label htmlFor="formFirstName">First Name</label>
                  <Field
                    name="firstName"
                    id="formFirstName"
                    className={`form-control ${
                      errors.firstName && touched.firstName ? "is-invalid" : ""
                    }`}
                    placeholder="First Name"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setEditAccountError("");
                    }}
                  />
                  {errors.firstName && touched.firstName ? (
                    <div className="invalid-feedback">{errors.firstName}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors.lastName && touched.lastName ? "has-validation" : ""
                  }`}
                >
                  <label htmlFor="formLastName">Last Name</label>
                  <Field
                    name="lastName"
                    id="formLastName"
                    className={`form-control ${
                      errors.lastName && touched.lastName ? "is-invalid" : ""
                    }`}
                    placeholder="Last Name"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setEditAccountError("");
                    }}
                  />
                  {errors.lastName && touched.lastName ? (
                    <div className="invalid-feedback">{errors.lastName}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors.phone && touched.phone ? "has-validation" : ""
                  }`}
                >
                  <label htmlFor="formPhone">Phone</label>
                  <Field
                    name="phone"
                    render={({ field }: any) => (
                      <MaskedInput
                        {...field}
                        mask={PHONE_NUMBER_MASK}
                        id="formPhone"
                        className={`form-control ${
                          errors.phone && touched.phone
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Phone Number"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          handleChange(e);
                          setEditAccountError("");
                        }}
                      />
                    )}
                  />
                  {errors.phone && touched.phone ? (
                    <div className="invalid-feedback">{errors.phone}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors.email && touched.email
                      ? "has-validation"
                      : ""
                  }`}
                >
                  <label htmlFor="formEmail">Email</label>
                  <Field
                    type="email"
                    name="email"
                    id="formEmail"
                    className={`form-control ${
                      errors.email && touched.email ? "is-invalid" : ""
                    }`}
                    placeholder="Email"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setEditAccountError("");
                    }}
                  />
                  {errors.email && touched.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="customer-edit-action">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  block
                >
                  {isSubmitting ? "Saving..." : "Save"}
                </Button>
                <Button
                  variant="link"
                  type="reset"
                  disabled={isSubmitting}
                  block
                >
                  <FontAwesomeIcon icon={faTimesCircle} />
                  Cancel Changes
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  return (
    <div className="user-search-activation-container">
      <div className="main-title">
        <Typography variant="h1">Client Search &amp; Activation</Typography>
        <Typography variant="p">
          Search for user by Customer ID number.
        </Typography>
      </div>
      <form className="search-container" onSubmit={handleSubmit}>
        <input
          className="form-control"
          name="search"
          placeholder="Search"
          disabled={isSearching}
          required
        />
        <Button type="submit" disabled={isSearching}>
          <FontAwesomeIcon
            icon={isSearching ? faSpinner : faSearch}
            pulse={isSearching}
          />
        </Button>
      </form>

      {(error || selectedCustomer || showEditView) && (
        <>
          <div className="separator">
            <span>Search Results</span>
          </div>
          {error ? (
            <div className="error-message">{error}</div>
          ) : showEditView ? (
            renderCustomerEdit()
          ) : (
            renderCustomerCard()
          )}
          {/* {error && }
          { !error && selectedCustomer && renderCustomerCard(selectedCustomer)} */}
        </>
      )}

      <VerticallyCenteredModal
        backdrop="static"
        keyboard={false}
        show={Boolean(success)}
        onHide={() => setSuccess("")}
        className="success-modal"
      >
        <>
          <p>{success}</p>
          <Button onClick={() => setSuccess("")}>Close</Button>
        </>
      </VerticallyCenteredModal>
    </div>
  );
};

export default ClientManagement;
