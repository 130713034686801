import React, { useState, useEffect } from "react";

import Table from "react-bootstrap/Table";

import Error from "../../components/Error";
import Loading from "../../components/Loading";
import Typography from "../../components/Typography";
import ChangePassword from "./ChangePassword";

import API from "../../utilities/api";
import { CTA_PHONE } from "../../utilities/constants";
import { formatDate, formatPhoneNumber } from "../../utilities/utility";
import { IAccountInformation } from "../../utilities/interface";

import "./style.scss";

const Account = () => {
  const [showChangePasswordModal, setChangePasswordModal] = useState(false);
  const [accountInfo, setAccountInfo] = useState<IAccountInformation | null>(null);
  const [error, setError] = useState<string>("");

  useEffect(() => {
    (async () => {
      await fetchAccountInformation();
    })();
  }, []);

  const fetchAccountInformation = async () => {
    try {
      const result = await API.get("/Users/accountInformation");
      setAccountInfo(result.data);
    } catch (err) {
      console.error(err);
      setError("Error in Fetching Account Information");
    }
  }

  if (accountInfo === null) {
    if (!error) {
      return <Loading text="Loading Account Information..." />;
    }

    return <Error/>;
  }

  const renderAccountDetails = () => {
    return (
      <Table>
        <tbody>
          <tr>
            <td>First Name</td>
            <td>{accountInfo.firstName}</td>
          </tr>
          <tr>
            <td>Last Name</td>
            <td>{accountInfo.lastName}</td>
          </tr>
          <tr>
            <td>Email Address</td>
            <td>{accountInfo.email}</td>
          </tr>
          <tr>
            <td>Phone Number</td>
            <td>{formatPhoneNumber(accountInfo.phoneNumber)}</td>
          </tr>
          <tr>
            <td>Trust Company, If Any</td>
            <td>{accountInfo.trustCompany}</td>
          </tr>
          <tr>
            <td>Trust Company Account Number, If Any</td>
            <td>{accountInfo.trustCompanyNumber}</td>
          </tr>
          <tr>
            <td>Date Account Opened</td>
            <td>{formatDate(accountInfo.accountOpened)}</td>
          </tr>
          <tr>
            <td>RRS account number</td>
            <td>{accountInfo.rrsAccountNumber}</td>
          </tr>
        </tbody>
      </Table>
    );
  };

  return (
    <div className="account-page">
      <Typography variant="h1" className="page-title">
        My Account
      </Typography>

      <div className="change-password-action">
        <button
          className="btn btn-primary btn-change-password"
          onClick={() => setChangePasswordModal(true)}
        >
          Change Password
        </button>
      </div>

      <div className="page-text">
        <Typography variant="h5">Account Details</Typography>
        <Typography variant="p">
          View your account info below. If you think any of these are incorrect,
          please call {CTA_PHONE}.
        </Typography>
      </div>

      <div className="account-details">{renderAccountDetails()}</div>

      <ChangePassword show={showChangePasswordModal} setShow={setChangePasswordModal} />
    </div>
  );
};

export default Account;
