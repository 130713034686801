import React, { useEffect, useState } from "react";

import Header from "./header";
import Sidebar from "./sidebar";

import { getFromStorage, setInStorage } from "../../../utilities/utility";
import { msalInstance } from "../../../index";
import { loginRequest } from "../../../authConfig";

import "./style.scss";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const account = msalInstance.getActiveAccount();

  const [showMenu, setShowMenu] = useState<boolean>(false)

  useEffect(() => {
    const userType = getFromStorage("userType");
    if (account && userType !== "admin") {
      (async () => {
        // this to be generated on any request needed or we can do on reload
        const payload = await msalInstance.acquireTokenSilent({
          ...loginRequest,
          account: account,
        });
        
        setInStorage(
          "auth",
          JSON.stringify({ access_token: payload.accessToken })
        );
        setInStorage("userType", "admin");
      })();
    }
  }, []);

  const handleMenuShow = () => {
    if (showMenu) {
      document.body.classList.remove('show-menu');
    } else {
      document.body.classList.add('show-menu');
    }
    setShowMenu(s => !s)
  }

  return (
    <div className="admin-panel-layout">
      <Header showMenu={showMenu} showHideMenu={handleMenuShow} />
      <Sidebar showHideMenu={handleMenuShow} />
      <div className="admin-body-container">{children}</div>
    </div>
  );
};

export default Layout;
