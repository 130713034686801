import { SET_SESSION_EXPIRED, SET_AUTH, REMOVE_AUTH } from "../actions/types";
import { ISessionAuth } from "./interface";

const initalState: ISessionAuth = {
  isSessionExpired: false,
  authData: {
    email: "",
    phoneNumber: "",
    access_token: null,
    firstName: "",
    lastName: "",
  },
};

export default function (state = initalState, action: any) {
  switch (action.type) {
    case SET_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };
    case SET_AUTH:
      return {
        ...state,
        authData: { ...action.payload },
      };
    case REMOVE_AUTH:
      return {
        ...state,
        authData: initalState,
      };
    default:
      return state;
  }
}
