import React, { useEffect, useState } from "react";
import { Redirect, useLocation } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Typography from "../../components/Typography";

import API from "../../utilities/api";
import { URL, FORM_VALIDATION } from "../../utilities/constants";
import {
  getCurrentUser,
  getMFAAuth,
  authenticationAction,
  logoutAction,
} from "../../utilities/utility";
import { ILocation, IMultiFactorCode } from "../../utilities/interface";
import DisclaimerModal from "../../components/DisclaimerModal";
import { removeAuth } from "../../workers/index";

import "./style.scss";

const Multifactor = () => {
  const location: ILocation = useLocation();

  const [method, setMethod] = useState("Email");
  const [formError, setFormError] = useState("");
  const [isSubmittingRequest, setIsSubmittingRequest] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [redirectToUrl, setRedirectToUrl] = useState("");

  const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
  const authData = getMFAAuth();

  useEffect(() => {
    if (getCurrentUser() != null) {
      setRedirectToReferrer(true);
    }
  }, []);

  if (authData == null || authData.access_token == null) {
    return <Redirect to={URL.LOGIN} />;
  }

  if (redirectToReferrer) {
    return <Redirect to={location.state?.referrer || URL.HOME} />;
  }

  if (redirectToUrl) {
    return <Redirect to={redirectToUrl} />;
  }

  async function reSendMfaCode() {
    logoutAction("");
    setRedirectToUrl(URL.LOGIN);
  }

  async function sendMfaCode() {
    try {
      removeAuth();

      await API.post("/Users/requestMFACode", {
        authCode: authData?.access_token,
        email: authData?.email,
        type: method,
      });

      setShowCodeInput(true);
    } catch (error) {
      setFormError("Error in sending security code.");
      console.error(error);
    }
  }

  if (showCodeInput) {
    // verify of 2FA code

    const initialValues = {
      email: authData.email,
      mfaCode: "",
    };

    const CodeSchema = Yup.object().shape({
      mfaCode: FORM_VALIDATION.CODE,
    });

    const handleSubmitCode = async (values: IMultiFactorCode) => {
      setFormError("");
      try {
        const response = await API.post("/Users/verifyMFACode", { ...values });
        const { data } = response;
        let user = {
          email: data.email,
          phoneNumber: data.phoneNumber,
          firstName: data.firstName,
          lastName: data.lastName,
        };
        authenticationAction(user, data.jwt);
        setShowDisclaimerModal(true);
      } catch (error) {
        setFormError(
          "The code does not match our records, or the code has expired."
        );
      }
    };

    if (redirectToReferrer) {
      return <Redirect to={location.state?.referrer || URL.HOME} />;
    }

    return (
      <>
        <div className="auth-page">
          <Typography variant="h1" className="page-title">
            Security Code
          </Typography>
          <Typography variant="h5" className="page-sub-title">
            Please enter the your security code that was sent to you.
          </Typography>

          <div className="form-wrapper">
            {formError && (
              <Alert
                variant="danger"
                onClose={() => {
                  setFormError("");
                }}
                dismissible
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>{formError}</span>
              </Alert>
            )}

            <Formik
              initialValues={initialValues}
              validationSchema={CodeSchema}
              onSubmit={async (values, { setSubmitting }) => {
                await handleSubmitCode(values);
                setTimeout(() => {
                  setSubmitting(false);
                }, 3000);
              }}
            >
              {({ isSubmitting, handleChange, errors, touched }) => (
                <Form>
                  <div
                    className={`form-group ${
                      errors.mfaCode && touched.mfaCode ? "has-validation" : ""
                    }`}
                  >
                    <Field
                      type="password"
                      name="mfaCode"
                      className={`form-control ${
                        errors.mfaCode && touched.mfaCode ? "is-invalid" : ""
                      }`}
                      placeholder="Security Code"
                      onChange={(e: React.FormEvent<HTMLInputElement>) => {
                        handleChange(e);
                      }}
                    />
                    {errors.mfaCode && touched.mfaCode ? (
                      <div className="invalid-feedback">{errors.mfaCode}</div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="form-action">
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {isSubmitting ? "Please Wait..." : "Submit"}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>

            <div className="actions">
              <Button variant="secondary" size="sm" onClick={reSendMfaCode}>
                Try Again
              </Button>
            </div>
          </div>
        </div>
        <DisclaimerModal
          show={showDisclaimerModal}
          onClose={() => setRedirectToReferrer(true)}
        />
      </>
    );
  } else {
    // selection of 2FA

    const handleSubmitTwoFASelect = async (
      e: React.FormEvent<HTMLFormElement>
    ) => {
      e.preventDefault();
      setIsSubmittingRequest(true);
      setFormError("");

      await sendMfaCode();

      setIsSubmittingRequest(false);
    };

    return (
      <>
        <div className="auth-page">
          <Typography variant="h1" className="page-title">
            Security Code Request
          </Typography>
          <Typography variant="h5" className="page-sub-title">
            Select how you want to receive your security code.
          </Typography>

          <div className="form-wrapper two-factor-select-wrapper">
            {formError && (
              <Alert
                variant="danger"
                onClose={() => {
                  setFormError("");
                }}
                dismissible
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>{formError}</span>
              </Alert>
            )}
            <form onSubmit={handleSubmitTwoFASelect}>
              <ul>
                <li>
                  <input
                    type="radio"
                    id="text"
                    name="auth_method"
                    onChange={() => setMethod("SMS")}
                    checked={method === "SMS"}
                    required
                  />
                  <label htmlFor="text">
                    <span>Text Message Me</span>
                    <small>@ {authData.phoneNumber}</small>
                  </label>
                </li>
                <li>
                  <input
                    type="radio"
                    id="email"
                    name="auth_method"
                    onChange={() => setMethod("Email")}
                    checked={method === "Email"}
                    required
                  />
                  <label htmlFor="email">
                    <span>Email Me</span>
                    <small>@ {authData.email}</small>
                  </label>
                </li>
              </ul>
              <div className="form-action">
                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmittingRequest}
                >
                  {isSubmittingRequest ? "Please Wait..." : "Send"}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  }
};

export default Multifactor;
