import thunk from "redux-thunk";
import logger from "redux-logger";
import { createStore, applyMiddleware, compose } from "redux";

import rootReducer from "./reducers";

const initialState = {};

const getMiddlewares = () => {
  if (process.env.ENVIRONMENT === "local") {
    return [thunk, logger];
  }

  return [thunk];
};

var composeVar;
if (window.__REDUX_DEVTOOLS_EXTENSION__) {
  composeVar = compose(
    applyMiddleware(...getMiddlewares()),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  );
} else {
  composeVar = compose(applyMiddleware(...getMiddlewares()));
}

const store = createStore(rootReducer, initialState, composeVar);

export default store;
