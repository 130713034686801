import {
  SET_ADMIN_SESSION_EXPIRED,
  SET_ADMIN_AUTH,
  REMOVE_ADMIN_AUTH,
} from "../actions/types";
import { IAdminSessionAuth } from "./interface";

const initalState: IAdminSessionAuth = {
  isSessionExpired: false,
  authData: {
    access_token: null,
  },
};

export default function (state = initalState, action: any) {
  switch (action.type) {
    case SET_ADMIN_SESSION_EXPIRED:
      return {
        ...state,
        isSessionExpired: action.payload,
      };
    case SET_ADMIN_AUTH:
      return {
        ...state,
        authData: { ...action.payload },
      };
    case REMOVE_ADMIN_AUTH:
      return {
        ...state,
        authData: initalState,
      };
    default:
      return state;
  }
}
