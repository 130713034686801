import React from "react";
import { Route, Redirect } from "react-router-dom";

import { ILocation } from "./utilities/interface";

import {
  getCurrentUser,
  setInStorage,
  removeFromStorage,
} from "./utilities/utility";
import { ADMIN_URL, URL } from "./utilities/constants";

import { msalInstance } from "./index";

type Props = {
  component: React.ComponentType<any>;
  path: string;
  exact: boolean;
  location?: ILocation | any;
  permitted?: boolean;
};

const ProtectedRoutes = ({ component: Component, ...rest }: Props) => {
  if (rest.location.pathname.includes("/admin")) {
    const account = msalInstance.getActiveAccount();

    return (
      <Route
        {...rest}
        render={(props) => {
          if (account) {
            return <Component {...props} />;
          } else {
            removeFromStorage("admin");
            setInStorage("logoutAdminStatus", "unauthorized");
            return (
              <Redirect
                to={{
                  pathname: ADMIN_URL.LOGIN,
                  state: { referrer: props.location.pathname },
                }}
              />
            );
          }
        }}
      />
    );
  } else {
    return (
      <Route
        {...rest}
        render={(props) => {
          if (getCurrentUser() != null) {
            return <Component {...props} />;
          } else {
            removeFromStorage("user");
            setInStorage("logoutStatus", "unauthorized");
            return (
              <Redirect
                to={{
                  pathname: URL.LOGIN,
                  state: { referrer: props.location.pathname },
                }}
              />
            );
          }
        }}
      />
    );
  }
};

export default ProtectedRoutes;
