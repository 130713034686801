import React, { useEffect, useState } from "react";
import { useLocation, Redirect, Link } from "react-router-dom";

import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import Error from "../../components/Error";
import Typography from "../../components/Typography";

import { getCurrentUser, getQueryStringValue } from "../../utilities/utility";
import API from "../../utilities/api";
import { URL, FORM_VALIDATION } from "../../utilities/constants";

import { ILocation, ICreatePassword } from "../../utilities/interface";

import "./style.scss";

const initialValues: ICreatePassword = {
  password: "",
  passwordConfirmation: "",
};

const SUBMISSION_STATE = {
  DEFAULT: "state-default",
  ERROR: "state-error",
  SUCCESS: "state-success",
};

const ResetPassword = () => {
  const [resetPasswordError, setResetPasswordError] = useState("");
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const location: ILocation = useLocation();

  const [formSubmissionState, setFormSubmissionState] = useState(
    SUBMISSION_STATE.DEFAULT
  );

  const resetToken = getQueryStringValue("resetToken");
  const email = getQueryStringValue("email");

  useEffect(() => {
    if (getCurrentUser() != null) {
      setRedirectToReferrer(true);
    }
  }, []);

  if (redirectToReferrer === true) {
    return <Redirect to={location.state?.referrer || URL.HOME} />;
  }

  if (!email || !resetToken) {
    return <Error bigText="Error" smallText="Invalid Url" />;
  }

  const ResetPasswordSchema = Yup.object().shape({
    password: FORM_VALIDATION.CREATE_PASSWORD,
    passwordConfirmation: FORM_VALIDATION.CONFIRM_PASSWORD,
  });

  const handleSubmit = async (
    values: ICreatePassword,
    formikHelper: FormikHelpers<ICreatePassword>
  ) => {
    setResetPasswordError("");

    try {
      await API.post("/Users/resetPassword", {
        email: email,
        token: resetToken,
        password: values.password,
      });
      setResetPasswordSuccess("Password updated.");
      setFormSubmissionState(SUBMISSION_STATE.SUCCESS);
      formikHelper.resetForm();
    } catch (e) {
      if (e.response && e.response.status === 401) {
        setResetPasswordError("You are not authorized to perform this action.");
      } else {
        console.error(e);
        setResetPasswordError("Error resetting password.");
      }
      setFormSubmissionState(SUBMISSION_STATE.ERROR);
    }
  };

  return (
    <div className="auth-page">
      <Typography variant="h1" className="page-title">
        Reset Password
      </Typography>
      <Typography variant="h5" className="page-sub-title">
        Please create a new personal password below.
      </Typography>

      <div className="form-wrapper">
        {resetPasswordError && (
          <Alert
            variant="danger"
            onClose={() => {
              setResetPasswordError("");
            }}
            dismissible
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{resetPasswordError}</span>
          </Alert>
        )}
        {resetPasswordSuccess ? (
          <div className="text-center">
            <Alert
              variant="success"
              onClose={() => {
                setResetPasswordSuccess("");
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>{resetPasswordSuccess}</span>
            </Alert>

            <Link to={URL.HOME}>Go to Login</Link>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={ResetPasswordSchema}
            onSubmit={async (values, formikHelper) => {
              await handleSubmit(values, formikHelper);
              formikHelper.setSubmitting(false);
            }}
          >
            {({ isSubmitting, handleChange, errors, touched }) => (
              <Form className={`reset-password ${formSubmissionState}`}>
                <div
                  className={`form-group ${
                    errors.password && touched.password ? "has-validation" : ""
                  }`}
                >
                  <Field
                    type="password"
                    name="password"
                    id="formPassword"
                    className={`form-control ${
                      errors.password && touched.password ? "is-invalid" : ""
                    }`}
                    placeholder="Password"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setResetPasswordError("");
                      setResetPasswordSuccess("");
                    }}
                  />
                  {errors.password && touched.password ? (
                    <div className="invalid-feedback">{errors.password}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div
                  className={`form-group ${
                    errors.passwordConfirmation && touched.passwordConfirmation
                      ? "has-validation"
                      : ""
                  }`}
                >
                  <Field
                    type="password"
                    name="passwordConfirmation"
                    id="passwordConfirmation"
                    className={`form-control ${
                      errors.passwordConfirmation &&
                      touched.passwordConfirmation
                        ? "is-invalid"
                        : ""
                    }`}
                    placeholder="Re-enter Password"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setResetPasswordError("");
                      setResetPasswordSuccess("");
                    }}
                  />
                  {errors.passwordConfirmation &&
                  touched.passwordConfirmation ? (
                    <div className="invalid-feedback">
                      {errors.passwordConfirmation}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-action">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please Wait..." : "Save"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
