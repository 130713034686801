import React, { useEffect } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import LayoutHeader from "./header";
import LayoutFooter from "./footer";

import Nav from "../Nav";

import { URL } from "../../utilities/constants";
import { logoutAction, getCurrentUser } from "../../utilities/utility";

import { IAppRootState } from "../../reducers/interface";

import "./style.scss";

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  const isSessionExpired = useSelector(
    (state: IAppRootState) => state.auth.isSessionExpired
  );

  useEffect(() => {
    if (isSessionExpired) {
      console.log("Session Expired");
    }
  }, [isSessionExpired]);

  if (isSessionExpired) {
    logoutAction("session_expired");

    return (
      <Redirect
        to={{
          pathname: URL.LOGIN,
          state: { referrer: window.location.pathname },
        }}
      />
    );
  }

  return (
    <div className={`userlogin-${getCurrentUser() !== null ? 'true' : 'false'}`}>
      <LayoutHeader />

      <div className="body-container">
        <div className="container">
          {children}
          {getCurrentUser() && <Nav />}
        </div>
      </div>

      <LayoutFooter />
    </div>
  );
};

export default Layout;
