import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";

import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Error from "../../components/Error";

import { URL } from "../../utilities/constants";
import { getQueryStringValue } from "../../utilities/utility";
import API from "../../utilities/api";

import "./style.scss";

const LoginInvitation = () => {
  const [redirectToUrl, setRedirectToUrl] = useState("");
  const [error, setError] = useState("");

  const urlToken = getQueryStringValue("onboardingtoken");
  const urlEmail = getQueryStringValue("email");

  useEffect(() => {
    if (!urlToken || !urlEmail) {
      setError("Invalid Url");
    } else {
      (async () => {
        await verifyToken();
      })();
    }
  }, []);

  const verifyToken = async () => {
    const queryParams = `?email=${urlEmail}&onboardingtoken=${urlToken}`
    try {
      const response = await API.get(`/Users/validate${queryParams}`)
      if (response.status === 200) {
        setRedirectToUrl(`${URL.CREATE_PASSWORD}${queryParams}`);
      } else {
        setError("Invalid Url");
      }
    } catch (e) {
      console.error(e);
      setError("Invalid Url");
    }
  };

  if (redirectToUrl) {
    return <Redirect to={redirectToUrl} />;
  }

  if (error) {
    return <Error bigText="Error" smallText={error} />;
  }

  return (
    <div className="login-invitation-page">
      <FontAwesomeIcon icon={faSpinner} pulse />
      Validating Token....
    </div>
  );
};

export default LoginInvitation;
