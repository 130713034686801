import * as workers from "../workers";
import { IAuth } from "../utilities/interface";

export const setSessionExpired = (value: boolean) =>
  workers.setSessionExpired(value);

export const setAuth = (value: IAuth) => workers.setAuth(value);

export const setAdminSessionExpired = (value: boolean) =>
  workers.setAdminSessionExpired(value);

export const setAdminAuth = (value: IAuth) => workers.setAdminAuth(value);
