import React from "react";
import { Link, useLocation } from "react-router-dom";

import { URL } from "../../utilities/constants";
import { ILocation } from "../../utilities/interface";

import "./style.scss";

const Nav = () => {
  const location: ILocation = useLocation();
  const { pathname } = location;
  console.log(">>> location", pathname);

  return (
    <div className="page-navigation-wrapper">
      <Link
        className={`btn btn-secondary nav-item${
          URL.HOME === pathname ? " active" : ""
        }`}
        to={URL.HOME}
      >
        Portfolio
      </Link>
      <Link
        className={`btn btn-secondary nav-item${
          URL.ASSETS === pathname ? " active" : ""
        }`}
        to={URL.ASSETS}
      >
        Assets
      </Link>
      <Link
        className={`btn btn-secondary nav-item${
          URL.NEWS === pathname ? " active" : ""
        }`}
        to={URL.NEWS}
      >
        News
      </Link>
      <Link
        className={`btn btn-secondary nav-item${
          URL.ACCOUNT === pathname ? " active" : ""
        }`}
        to={URL.ACCOUNT}
      >
        Account
      </Link>
    </div>
  );
};

export default Nav;
