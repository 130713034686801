import { combineReducers } from "redux";

import authReducer from "./authReducer";
import adminAuthReducer from "./adminAuthReducer";

// will throw errors until we supply a specific reducer into the object
export default combineReducers({
  auth: authReducer,
  adminAuth: adminAuthReducer,
});
