import React from "react";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

import { DISCLAIMER } from "../../utilities/constants";

import "./style.scss";

type Props = {
  show: boolean;
  onClose: React.MouseEventHandler;
};

const DisclaimerModal = ({ show, onClose }: Props) => {
  return (
    <Modal
      show={show}
      onHide={onClose}
      backdrop="static"
      keyboard={false}
      className="disclaimer-modal"
    >
      <Modal.Body>
        <div>
          <strong>Disclaimer:</strong> {DISCLAIMER}
        </div>
        <Button variant="primary" onClick={onClose}>
          Got It
        </Button>
      </Modal.Body>
    </Modal>
  );
};

export default DisclaimerModal;
