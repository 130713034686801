import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";

import "./index.scss";
import Routes from "./routes";

import { Provider } from "react-redux";
import store from "./store";

import {setInStorage} from "./utilities/utility";

import reportWebVitals from "./reportWebVitals";

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  // AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    // const payload = event.payload as AuthenticationResult;
    const payload: any = event.payload;
    setInStorage("userType", "admin");
    setInStorage("auth", JSON.stringify({access_token: payload.accessToken}));
    const account = payload.account;
    msalInstance.setActiveAccount(account);

  }
});

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <Routes pca={msalInstance} />
      </Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
