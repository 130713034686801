import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import "./index.scss";

type Props = {
  text: string;
};

const Loading = ({ text }: Props) => (
  <div className="loading-message">
    <FontAwesomeIcon icon={faSpinner} pulse/>
    {text}
  </div>
);

export default Loading;
