import React, { useEffect, useState } from "react";
import { useLocation, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import { useMsal } from "@azure/msal-react";

import Alert from "react-bootstrap/Alert";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Typography from "../../../components/Typography/index";

import {
  getCurrentAdmin,
  today,
  getFromStorage,
  removeFromStorage,
  getLogoutMessage,
  logoutAdminAction,
} from "../../../utilities/utility";
import { ADMIN_URL, EXTERNAL_URL } from "../../../utilities/constants";
import { ILocation } from "../../../utilities/interface";
import { loginRequest } from "../../../authConfig";
import { msalInstance } from "../../../index";

import { setAdminSessionExpired } from "../../../actions";

import "./login.scss";
import loginBtn from "./images/ms-symbollockup_signin_dark.svg";

const Login = () => {
  const dispatch = useDispatch();
  const { instance } = useMsal();

  const [loginError, setLoginError] = useState("");
  const [logoutMessage, setLogoutMessage] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [redirectToUrl] = useState("");
  const location: ILocation = useLocation();
  const account = msalInstance.getActiveAccount();

  useEffect(() => {
    // if the user is already logged in, no need to do it again
    if (getCurrentAdmin() != null || account) {
      setRedirectToReferrer(true);
    } else {
      logoutAdminAction("logout");
    }

    removeFromStorage("logoutStatus");
    const logoutStatus = getFromStorage("logoutAdminStatus");
    if (logoutStatus) {
      setLogoutMessage(getLogoutMessage(logoutStatus));
    }
    dispatch(setAdminSessionExpired(false));
  }, []);

  if (redirectToReferrer) {
    return <Redirect to={location.state?.referrer || ADMIN_URL.HOME} />;
  }

  if (redirectToUrl) {
    return <Redirect to={redirectToUrl} />;
  }

  const removeLogoutMessage = () => {
    setLogoutMessage("");
    removeFromStorage("logoutStatus");
    removeFromStorage("logoutAdminStatus");
  };

  const handleMsalLogin = async () => {
    try {
      removeLogoutMessage();
      // instance.loginRedirect(loginRequest);
      await instance.loginPopup(loginRequest);
      setRedirectToReferrer(true);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="admin-login-page">
      <div className="background-overlay">
        <div className="login-box">
          <div className="login-logo"></div>
          <div className="login-form">
            <Typography variant="h5" className="login-title">
              Sign In to Your Account
            </Typography>
            {logoutMessage && (
              <Alert
                variant="danger"
                onClose={() => {
                  removeLogoutMessage();
                }}
                dismissible
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>{logoutMessage}</span>
              </Alert>
            )}
            {loginError && (
              <Alert
                variant="danger"
                onClose={() => {
                  setLoginError("");
                }}
                dismissible
              >
                <FontAwesomeIcon icon={faExclamationTriangle} />
                <span>{loginError}</span>
              </Alert>
            )}
            <div className="microsoft-login">
              <img
                src={loginBtn}
                style={{ cursor: "pointer" }}
                onClick={handleMsalLogin}
              />
            </div>
          </div>

          <div className="login-footer">
            <a
              href={EXTERNAL_URL.MARKETING_SITE}
              target="_blank"
              rel="noreferrer"
            >
              <div className="logo-icon"></div>
            </a>
            <div className="copyright-text">
              Copyright {today("YYYY")} Red Rock Secured.{" "}
              <span>All rights reserved</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
