import React, { useEffect, useState } from "react";
import { Link, useLocation, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faKey,
  faUser,
} from "@fortawesome/free-solid-svg-icons";

import LayoutFooter from "../../components/Layout/footer";
import Typography from "../../components/Typography/index";

import {
  loginAction,
  getCurrentUser,
  getFromStorage,
  removeFromStorage,
  getLogoutMessage,
} from "../../utilities/utility";
import API from "../../utilities/api";
import { ILocation, ILoginValues } from "../../utilities/interface";
import { FORM_VALIDATION, URL } from "../../utilities/constants";

import { setSessionExpired } from "../../actions";

import "./login.scss";

const initialValues: ILoginValues = {
  email: "",
  password: "",
};

const Login = () => {
  const dispatch = useDispatch();

  const [loginError, setLoginError] = useState("");
  const [logoutMessage, setLogoutMessage] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [redirectToUrl, setRedirectToUrl] = useState("");
  const location: ILocation = useLocation();

  useEffect(() => {
    // if the user is already logged in, no need to do it again
    if (getCurrentUser() != null) {
      setRedirectToReferrer(true);
    }

    removeFromStorage("logoutAdminStatus");
    const logoutStatus = getFromStorage("logoutStatus");
    if (logoutStatus) {
      setLogoutMessage(getLogoutMessage(logoutStatus));
    }
    dispatch(setSessionExpired(false));
  }, []);

  const SignupSchema = Yup.object().shape({
    email: FORM_VALIDATION.EMAIL,
    password: FORM_VALIDATION.PASSWORD,
  });

  function removeLogoutMessage() {
    setLogoutMessage("");
    removeFromStorage("logoutStatus");
    removeFromStorage("logoutAdminStatus");
  }

  async function handleSubmit(values: ILoginValues) {
    removeLogoutMessage();
    setLoginError("");
    dispatch(setSessionExpired(false));

    try {
      const response = await API.post("/Users/authenticate", {
        username: values.email,
        password: values.password,
      });
      const { data } = response;
      loginAction({
        email: data.email,
        phoneNumber: data.phoneNumber,
        firstName: data.firstName,
        lastName: data.lastName,
        access_token: data.authCode,
      });

      setRedirectToUrl(URL.MULTI_FACTOR_AUTH);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setLoginError("Incorrect Username or Password");
      } else {
        console.error(error);
        setLoginError("Error Authorizing User");
      }
    }
  }

  if (redirectToReferrer) {
    return <Redirect to={location.state?.referrer || URL.HOME} />;
  }

  if (redirectToUrl) {
    return <Redirect to={redirectToUrl} />;
  }

  return (
    <>
      <div className="login-page">
        <div className="banner-bg"></div>
        <div className="left-section">
          <div className="logo"></div>
        </div>
        <div className="right-section">
          <div className="login-wrapper">
            <div className="login-title">
              <Typography variant="h1">View Your Metals Portfolio</Typography>
            </div>
            <div className="login-form">
              <Typography variant="h5">Login or Request an Account.</Typography>
              {logoutMessage && (
                <Alert
                  variant="danger"
                  onClose={() => {
                    removeLogoutMessage();
                  }}
                  dismissible
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <span>{logoutMessage}</span>
                </Alert>
              )}
              {loginError && (
                <Alert
                  variant="danger"
                  onClose={() => {
                    setLoginError("");
                  }}
                  dismissible
                >
                  <FontAwesomeIcon icon={faExclamationTriangle} />
                  <span>{loginError}</span>
                </Alert>
              )}
              <Formik
                initialValues={initialValues}
                validationSchema={SignupSchema}
                onSubmit={async (values, { setSubmitting }) => {
                  await handleSubmit(values);
                  setSubmitting(false);
                }}
              >
                {({ isSubmitting, handleChange, errors, touched }) => (
                  <Form>
                    <div
                      className={`form-group ${
                        errors.email && touched.email ? "has-validation" : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faUser} />
                      <Field
                        type="email"
                        name="email"
                        id="fromUserName"
                        className={`form-control ${
                          errors.email && touched.email ? "is-invalid" : ""
                        }`}
                        placeholder="Email Address"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          handleChange(e);
                          removeLogoutMessage();
                          setLoginError("");
                        }}
                      />
                      {errors.email && touched.email ? (
                        <div className="invalid-feedback">{errors.email}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      className={`form-group ${
                        errors.password && touched.password
                          ? "has-validation"
                          : ""
                      }`}
                    >
                      <FontAwesomeIcon icon={faKey} />
                      <Field
                        type="password"
                        name="password"
                        id="formPassword"
                        className={`form-control ${
                          errors.password && touched.password
                            ? "is-invalid"
                            : ""
                        }`}
                        placeholder="Password"
                        onChange={(e: React.FormEvent<HTMLInputElement>) => {
                          handleChange(e);
                          removeLogoutMessage();
                          setLoginError("");
                        }}
                      />
                      {errors.password && touched.password ? (
                        <div className="invalid-feedback">
                          {errors.password}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <Button
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                      block
                    >
                      {isSubmitting ? "Please Wait..." : "View Your Account"}
                    </Button>
                  </Form>
                )}
              </Formik>
              <div className="forgot-password">
                <Link to={URL.FORGOT_PASSWORD}>Forgot Password?</Link>
              </div>
              <div className="request-account-wrapper">
                <Button variant="secondary" size="sm">
                  Request an Account
                </Button>
                <Link to={URL.FORGOT_PASSWORD}>
                  Get My Account Request Status
                </Link>
              </div>
            </div>

            <LayoutFooter />
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
