import axios from "axios";
import { API_PREFIX, MY_STORAGE } from "./constants";

const API = axios.create({
  // .. where we make our configurations
  baseURL: API_PREFIX,
  timeout: 60000, // Now all requests using this instance will wait 30 seconds before timing out
});

API.defaults.headers.get["Accept"] = "application/json";

API.defaults.headers.post["Accept"] = "application/json";
API.defaults.headers.post["Content-Type"] = "application/json";

API.interceptors.request.use((value) => {
  const authString = MY_STORAGE.getItem("auth");
  if (authString) {
    const auth = JSON.parse(authString);
    value.headers.authorization = `Bearer ${auth.access_token}`;
  }
  return value;
});

API.interceptors.response.use(
  (response) => responseSuccessHandler(response),
  (error) => responseErrorHandler(error)
);

const responseSuccessHandler = (response: any) => {
  return response;
};

const responseErrorHandler = (error: any) => {
  return Promise.reject(error);
};

export default API;
