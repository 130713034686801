import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";

import { ADMIN_URL } from "../../../utilities/constants";
import { ILocation } from "../../../utilities/interface";

type Props = {
  showHideMenu: () => void;
};

const Sidebar = ({ showHideMenu }: Props) => {
  const { instance } = useMsal();

  const location: ILocation = useLocation();
  const { pathname } = location;

  const handleMsalLogout = () => {
    try {
      if (window.confirm("Are you sure you want to logout")) {
        // instance.logoutRedirect();
        instance.logoutPopup({
          mainWindowRedirectUri: "/admin/login",
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className="sidebar">
      <div className="logout-top" onClick={handleMsalLogout}>
        <FontAwesomeIcon icon={faTimesCircle} />
        Logout
      </div>
      <div className="logo"></div>
      <div className="nav-items">
        <ul>
          <li>
            <Link
              onClick={showHideMenu}
              className={`nav-item${
                ADMIN_URL.HOME === pathname ? " active" : ""
              }`}
              to={ADMIN_URL.HOME}
            >
              Client Management
            </Link>
          </li>
          <li className="logout">
            <a onClick={handleMsalLogout} className="nav-item" >
              <FontAwesomeIcon icon={faTimesCircle} />
              LOGOUT
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;
