import { Configuration, PopupRequest } from "@azure/msal-browser";

// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: `${process.env.REACT_APP_MICROSOFT_CLIENT_ID}`,
        authority: `${process.env.REACT_APP_MICROSOFT_AUTHORITY}`,
        redirectUri: "/admin/login",
        postLogoutRedirectUri: "/admin/login"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: [`${process.env.REACT_APP_MICROSOFT_SCOPE}`]
};
