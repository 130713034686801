import React, { useState } from "react";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faExclamationTriangle,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import VerticallyCenteredModal from "../../components/Modal/vertically_centered";

import API from "../../utilities/api";
import { IChangePassword } from "../../utilities/interface";
import { FORM_VALIDATION } from "../../utilities/constants";

type Props = {
  show: boolean;
  setShow: (value: boolean) => void;
};

const initialValues: IChangePassword = {
  currentPassword: "",
  password: "",
  passwordConfirmation: "",
};

const ChangePassword = (props: Props) => {
  const [changePasswordError, setChangePasswordError] = useState("");
  const [changePasswordSuccess, setChangePasswordSuccess] = useState(false);

  const CreatePasswordSchema = Yup.object().shape({
    currentPassword: FORM_VALIDATION.PASSWORD,
    password: FORM_VALIDATION.CREATE_PASSWORD,
    passwordConfirmation: FORM_VALIDATION.CONFIRM_PASSWORD,
  });

  const handleSubmit = async (values: IChangePassword) => {
    setChangePasswordError("");

    try {
      await API.post("/Users/changePassword", {
        currentPassword: values.currentPassword,
        newPassword: values.password,
      });
      setChangePasswordSuccess(true);
    } catch (error) {
      console.error(error);
      setChangePasswordError("Error Changing Password");
    }
  };

  const renderChangePasswordForm = () => {
    return (
      <div className="form-wrapper">
        {changePasswordError && (
          <Alert
            variant="danger"
            onClose={() => {
              setChangePasswordError("");
            }}
            dismissible
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{changePasswordError}</span>
          </Alert>
        )}

        <Formik
          initialValues={initialValues}
          validationSchema={CreatePasswordSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleChange, errors, touched }) => (
            <Form>
              <div
                className={`form-group ${
                  errors.currentPassword && touched.currentPassword
                    ? "has-validation"
                    : ""
                }`}
              >
                <Field
                  type="password"
                  name="currentPassword"
                  id="formCurrentPassword"
                  className={`form-control ${
                    errors.currentPassword && touched.currentPassword
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Current Password"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setChangePasswordError("");
                  }}
                />
                {errors.currentPassword && touched.currentPassword ? (
                  <div className="invalid-feedback">
                    {errors.currentPassword}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`form-group ${
                  errors.password && touched.password ? "has-validation" : ""
                }`}
              >
                <Field
                  type="password"
                  name="password"
                  id="formPassword"
                  className={`form-control ${
                    errors.password && touched.password ? "is-invalid" : ""
                  }`}
                  placeholder="New Password"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setChangePasswordError("");
                  }}
                />
                {errors.password && touched.password ? (
                  <div className="invalid-feedback">{errors.password}</div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`form-group ${
                  errors.passwordConfirmation && touched.passwordConfirmation
                    ? "has-validation"
                    : ""
                }`}
              >
                <Field
                  type="password"
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  className={`form-control ${
                    errors.passwordConfirmation && touched.passwordConfirmation
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Re-enter New Password"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setChangePasswordError("");
                  }}
                />
                {errors.passwordConfirmation && touched.passwordConfirmation ? (
                  <div className="invalid-feedback">
                    {errors.passwordConfirmation}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-action">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Please Wait..." : "Change Password"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };

  const renderPasswordChangedSuccess = () => {
    return (
      <div className="password-changed-success">
        <FontAwesomeIcon icon={faCheckCircle} />
        <div>Password Changed Successfully</div>
      </div>
    );
  };

  return (
    <VerticallyCenteredModal
      backdrop="static"
      keyboard={false}
      show={props.show}
      onHide={() => props.setShow(false)}
      className="change-password-modal"
      modelTile={`${changePasswordSuccess ? "" : "Change Password"}`}
    >
      {!changePasswordError && changePasswordSuccess
        ? renderPasswordChangedSuccess()
        : renderChangePasswordForm()}
    </VerticallyCenteredModal>
  );
};

export default ChangePassword;
