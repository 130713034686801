import React from "react";
import Chart from "react-google-charts";
import _ from "lodash"

import { getChartSize, getColorByMetalType } from "../../utilities/utility";
import { IPortfolioMetalsOwn } from "../../utilities/interface";

import "./summary-graph.scss";

type dataType = {
  color: string;
  type: string;
  value: number;
};

type Props = {
  data?: Array<IPortfolioMetalsOwn> | undefined;
};

const SummaryGraph = ({ data }: Props) => {
  if (!data) {
    return <></>;
  }

  const formattedData = data.map((d) => {
    return {
      color: getColorByMetalType(d.type),
      ...d,
    };
  });

  const chartData: Array<Array<string | number>> = [["Metal", "Count"]];
  const labelColor: Array<string> = [];
  formattedData.forEach((d: dataType) => {
    labelColor.push(d.color);
    chartData.push([d.type, Number(d.value)]);
  });

  const chartSizeObj = getChartSize();
  const chartAreaObj = { width: "90%", height: "90%" };

  let chartOptions = {
    title: "",
    colors: labelColor,
    backgroundColor: "none",
    chartArea: chartAreaObj,
    width: chartSizeObj.width,
    height: chartSizeObj.height,
    legend: "none",
    enableInteractivity: false,
    pieSliceText: "none",
  };

  const renderLabels = () => {
    const totalVal = formattedData.reduce((total, obj) => obj.value + total, 0);
    return formattedData.map((d: dataType) => {
      const percentage = (d.value / totalVal) * 100
      return (
        <li key={d.type}>
          <span style={{ background: d.color }}></span>
          {_.round(percentage, 2)}% {d.type}
        </li>
      );
    });
  };

  return (
    <div className="portfolio-summary-chart">
      <div className="chart-title">YOUR METALS BY VALUE</div>
      <div className="chart-container">
        <div className="chart-wrapper">
          <Chart
            className="metas-you-own-chart"
            chartType="PieChart"
            graphID="MetalsYouOwn"
            data={chartData}
            options={chartOptions}
            loader={<div>Loading Chart</div>}
          />
        </div>
        <div className="label-wrapper">
          <ul>{renderLabels()}</ul>
        </div>
      </div>
    </div>
  );
};

export default SummaryGraph;
