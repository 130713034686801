import * as actions from "../actions/types";
import { IAuth } from "../utilities/interface";

/**
 * Sets Session Expiry
 *
 * @returns {function}
 */
export const setSessionExpired = (status: boolean) => (dispatch: any) => {
  dispatch({
    type: actions.SET_SESSION_EXPIRED,
    payload: status,
  });
};

/**
 * Sets Auth Data
 *
 * @returns {function}
 */
export const setAuth = (authData: IAuth) => (dispatch: any) => {
  dispatch({
    type: actions.SET_AUTH,
    payload: authData,
  });
};

/**
 * Removes Auth Data
 *
 * @returns {function}
 */
export const removeAuth = () => (dispatch: any) => {
  dispatch({
    type: actions.REMOVE_AUTH,
    payload: true,
  });
};

/**
 * Sets Session Expiry
 *
 * @returns {function}
 */
 export const setAdminSessionExpired = (status: boolean) => (dispatch: any) => {
  dispatch({
    type: actions.SET_ADMIN_SESSION_EXPIRED,
    payload: status,
  });
};

/**
 * Sets Auth Data
 *
 * @returns {function}
 */
export const setAdminAuth = (authData: IAuth) => (dispatch: any) => {
  dispatch({
    type: actions.SET_ADMIN_AUTH,
    payload: authData,
  });
};

/**
 * Removes Admin Auth Data
 *
 * @returns {function}
 */
export const removeAdminAuth = () => (dispatch: any) => {
  dispatch({
    type: actions.REMOVE_ADMIN_AUTH,
    payload: true,
  });
};
