import React, { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import Error from "../../components/Error";
import Typography from "../../components/Typography";

import API from "../../utilities/api";
import { URL, FORM_VALIDATION } from "../../utilities/constants";
import { getCurrentUser, getQueryStringValue } from "../../utilities/utility";

import { ICreatePassword, ILocation } from "../../utilities/interface";

import "./style.scss";

const initialValues: ICreatePassword = {
  password: "",
  passwordConfirmation: "",
};

const CreatePassword = () => {
  const [createPasswordError, setCreatePasswordError] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [redirectToUrl, setRedirectToUrl] = useState("");
  const location: ILocation = useLocation();

  const urlToken = getQueryStringValue("onboardingToken");
  const urlEmail = getQueryStringValue("email");
  const currentUser = getCurrentUser();

  useEffect(() => {
    if (currentUser != null) {
      setRedirectToReferrer(true);
    }
  }, []);

  const CreatePasswordSchema = Yup.object().shape({
    password: FORM_VALIDATION.CREATE_PASSWORD,
    passwordConfirmation: FORM_VALIDATION.CONFIRM_PASSWORD,
  });

  const handleSubmit = async (values: ICreatePassword) => {
    setCreatePasswordError("");

    try {
      const response = await API.post("/Users/registerPassword", {
        onboardingToken: urlToken,
        email: urlEmail,
        password: values.password,
      });
      if (response.status === 200) {
        // todo : this needs work. need a confirmation message & click to go to login.
        setRedirectToUrl(URL.LOGIN);
        // nothing to do but redirect to home
        //        const { data } = response;
        //        loginAction({
        //          email: data.email,
        //          phoneNumber: data.phoneNumber,
        //          access_token: data.jwt,
        //          firstName: data.firstName,
        //          lastName: data.lastName
        //        });
      } else if (response.status === 400) {
        setCreatePasswordError(response.data[0]);
      } else if (response.status === 401) {
        setCreatePasswordError(
          "You are not authorized to perform this action."
        );
      } else {
        setCreatePasswordError("Error Creating Password");
      }
    } catch (error) {
      console.error(error);
      setCreatePasswordError("Error Creating Password");
    }
  };

  if (!urlToken || !urlEmail) {
    return <Error bigText="Error" smallText="Invalid Link" />;
  }

  if (redirectToReferrer) {
    return <Redirect to={location.state?.referrer || URL.HOME} />;
  }

  if (redirectToUrl) {
    return <Redirect to={redirectToUrl} />;
  }

  return (
    <div className="auth-page">
      <Typography variant="h1" className="page-title">
        Welcome {currentUser?.firstName}!
      </Typography>
      <Typography variant="h5" className="page-sub-title">
        Please create your personal password below.
      </Typography>

      <div className="form-wrapper">
        {createPasswordError && (
          <Alert
            variant="danger"
            onClose={() => {
              setCreatePasswordError("");
            }}
            dismissible
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{createPasswordError}</span>
          </Alert>
        )}
        <Formik
          initialValues={initialValues}
          validationSchema={CreatePasswordSchema}
          onSubmit={async (values, { setSubmitting }) => {
            await handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {({ isSubmitting, handleChange, errors, touched }) => (
            <Form>
              <div className="form-group">
                <div className="form-control">{urlEmail}</div>
              </div>
              <div
                className={`form-group ${
                  errors.password && touched.password ? "has-validation" : ""
                }`}
              >
                <Field
                  type="password"
                  name="password"
                  id="formPassword"
                  className={`form-control ${
                    errors.password && touched.password ? "is-invalid" : ""
                  }`}
                  placeholder="Password"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setCreatePasswordError("");
                  }}
                />
                {errors.password && touched.password ? (
                  <div className="invalid-feedback">{errors.password}</div>
                ) : (
                  ""
                )}
              </div>
              <div
                className={`form-group ${
                  errors.passwordConfirmation && touched.passwordConfirmation
                    ? "has-validation"
                    : ""
                }`}
              >
                <Field
                  type="password"
                  name="passwordConfirmation"
                  id="passwordConfirmation"
                  className={`form-control ${
                    errors.passwordConfirmation && touched.passwordConfirmation
                      ? "is-invalid"
                      : ""
                  }`}
                  placeholder="Re-enter Password"
                  onChange={(e: React.FormEvent<HTMLInputElement>) => {
                    handleChange(e);
                    setCreatePasswordError("");
                  }}
                />
                {errors.passwordConfirmation && touched.passwordConfirmation ? (
                  <div className="invalid-feedback">
                    {errors.passwordConfirmation}
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="form-action">
                <Button variant="primary" type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Please Wait..." : "Save"}
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default CreatePassword;
