import React from "react";
import { Route, Switch } from "react-router-dom";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { IPublicClientApplication } from "@azure/msal-browser";

import ProtectedRoutes from "./authRoutes";
import Layout from "./components/Layout";
import AdminLayout from "./admin/components/Layout";

import Home from "./pages/Home";
import News from "./pages/News";
import Assets from "./pages/Assets";
import Account from "./pages/Account";
import NotFound from "./pages/NotFound";
import Login from "./pages/Auth/Login";
import Multifactor from "./pages/Auth/Multifactor";
import CreatePassword from "./pages/Auth/CreatePassword";
import LoginInvitation from "./pages/Auth/LoginInvitation";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";

import AdminLogin from "./admin/pages/Auth/Login";
import ClientManagement from "./admin/pages/ClientManagement";

import { URL } from "./utilities/constants";
import { ADMIN_URL } from "./utilities/constants";

type AppProps = {
  pca: IPublicClientApplication;
};

const Routes = ({ pca }: AppProps) => {
  if (window.location.pathname.includes("/admin")) {
    return (
      <Switch>
        <MsalProvider instance={pca}>
          <AdminPages />
        </MsalProvider>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={URL.LOGIN} component={Login} />

      <ClientPages />
    </Switch>
  );
};

const ClientPages = () => {
  return (
    <Layout>
      <Switch>
        <Route exact path={URL.CREATE_PASSWORD} component={CreatePassword} />
        <Route exact path={URL.LOGIN_INVITATION} component={LoginInvitation} />
        <Route exact path={URL.MULTI_FACTOR_AUTH} component={Multifactor} />
        <Route exact path={URL.FORGOT_PASSWORD} component={ForgotPassword} />
        <Route exact path={URL.RESET_PASSWORD} component={ResetPassword} />

        <ProtectedRoutes exact path={URL.HOME} component={Home} />
        <ProtectedRoutes exact path={URL.NEWS} component={News} />
        <ProtectedRoutes exact path={URL.ASSETS} component={Assets} />
        <ProtectedRoutes exact path={URL.ACCOUNT} component={Account} />

        <Route exact path={"*"} component={NotFound} />
      </Switch>
    </Layout>
  );
};

const AdminPages = () => {
  return (
    <Switch>
      <Route exact path={ADMIN_URL.LOGIN} component={AdminLogin} />

      <AdminLayout>
        <Switch>
          <ProtectedRoutes
            exact
            path={ADMIN_URL.HOME}
            component={ClientManagement}
          />
        </Switch>
      </AdminLayout>
    </Switch>
  );
};

export default Routes;
