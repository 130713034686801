import React, { useEffect, useState } from "react";
import { useLocation, Redirect, Link } from "react-router-dom";

import { Formik, Form, Field, FormikHelpers } from "formik";
import * as Yup from "yup";

import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationTriangle,
} from "@fortawesome/free-solid-svg-icons";

import Typography from "../../components/Typography";

import { getCurrentUser } from "../../utilities/utility";
import API from "../../utilities/api";
import { URL, FORM_VALIDATION } from "../../utilities/constants";

import { ILocation, IForgotPasswordValues } from "../../utilities/interface";

import "./style.scss";

const initialValues: IForgotPasswordValues = {
  email: "",
};

const SUBMISSION_STATE = {
  DEFAULT: "state-default",
  ERROR: "state-error",
  SUCCESS: "state-success",
};

const ForgotPassword = () => {
  const [forgotPasswordError, setForgotPasswordError] = useState("");
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState("");
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const location: ILocation = useLocation();

  const [formSubmissionState, setFormSubmissionState] = useState(
    SUBMISSION_STATE.DEFAULT
  );

  useEffect(() => {
    if (getCurrentUser() != null) {
      setRedirectToReferrer(true);
    }
  }, []);

  if (redirectToReferrer === true) {
    return <Redirect to={location.state?.referrer || URL.HOME} />;
  }

  const ForgotPasswordSchema = Yup.object().shape({
    email: FORM_VALIDATION.EMAIL,
  });

  const handleSubmit = async (
    values: IForgotPasswordValues,
    formikHelper: FormikHelpers<IForgotPasswordValues>
  ) => {
    setForgotPasswordError("");

    try {
      await API.post("/Users/requestPasswordReset", {
        email: values.email,
      });
      setForgotPasswordSuccess(
        "Success! If an account with that email address exists, an email with instructions has been sent. Please check your email."
      );
      setFormSubmissionState(SUBMISSION_STATE.SUCCESS);
      formikHelper.resetForm();
    } catch (e) {
      console.error(e);
      setForgotPasswordError("Error submitting forgot password request.");
    }
  };

  return (
    <div className="auth-page">
      <Typography variant="h1" className="page-title">
        Forgot Password
      </Typography>
      <Typography variant="h5" className="page-sub-title">
        Please request a new personal password below.
      </Typography>

      <div className="form-wrapper">
        {forgotPasswordError && (
          <Alert
            variant="danger"
            onClose={() => {
              setForgotPasswordError("");
            }}
            dismissible
          >
            <FontAwesomeIcon icon={faExclamationTriangle} />
            <span>{forgotPasswordError}</span>
          </Alert>
        )}

        {forgotPasswordSuccess ? (
          <div className="text-center">
            <Alert
              variant="success"
              onClose={() => {
                setForgotPasswordSuccess("");
              }}
            >
              <FontAwesomeIcon icon={faCheckCircle} />
              <span>{forgotPasswordSuccess}</span>
            </Alert>

            <Link to={URL.HOME}>Go to Login</Link>
          </div>
        ) : (
          <Formik
            initialValues={initialValues}
            validationSchema={ForgotPasswordSchema}
            onSubmit={async (values, formikHelper) => {
              await handleSubmit(values, formikHelper);
              formikHelper.setSubmitting(false);
            }}
          >
            {({ isSubmitting, handleChange, errors, touched }) => (
              <Form className={`forgot-password ${formSubmissionState}`}>
                <div
                  className={`form-group ${
                    errors.email && touched.email ? "has-validation" : ""
                  }`}
                >
                  <Field
                    type="email"
                    name="email"
                    id="email"
                    className={`form-control ${
                      errors.email && touched.email ? "is-invalid" : ""
                    }`}
                    placeholder="Email Address"
                    onChange={(e: React.FormEvent<HTMLInputElement>) => {
                      handleChange(e);
                      setForgotPasswordError("");
                      setForgotPasswordSuccess("");
                    }}
                  />
                  {errors.email && touched.email ? (
                    <div className="invalid-feedback">{errors.email}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="form-action">
                  <Button
                    variant="primary"
                    type="submit"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? "Please Wait..." : "Submit"}
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
};

export default ForgotPassword;
