import React from "react";

import cn from "classnames";
import Card from "react-bootstrap/Card";

import {formatCurrency} from "../../utilities/utility"

import "./summary-card.scss";

type Props = {
  label: string;
  value: number;
  className?: string;
};

const SummaryCard = ({ label, value, className }: Props) => {
  return (
    <Card
      body
      className={cn("portfolio-summary-card", {
        [`${className}`]: className,
      })}
    >
      <div className="card-label">{label}</div>
      <div className="card-value">{formatCurrency(value)}</div>
    </Card>
  );
};

export default SummaryCard;
