import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";

type Props = {
  showHideMenu: () => void;
  showMenu: boolean;
}

const Header = ({showMenu, showHideMenu}: Props) => {
  return (
    <>
      <div className="menu-overlay" onClick={showHideMenu}></div>
      <div className="header">
        <div className="logo"></div>
        <FontAwesomeIcon icon={showMenu ? faTimes : faBars} onClick={showHideMenu}/>
      </div>
    </>
  );
};

export default Header;
