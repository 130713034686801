import React from "react";

import _ from "lodash"
import cn from "classnames";
import Card from "react-bootstrap/Card";

import {formatCurrency} from "../../utilities/utility"

import "./assets-details-card.scss";

type Props = {
  title: string;
  currentValue: number;
  acquisitionCost: number;
  quantity: number;
  ounces: number;
  className?: string;
};

const AssetsDetailsCard = ({
  title,
  currentValue,
  acquisitionCost,
  quantity,
  ounces,
  className,
}: Props) => {
  return (
    <Card
      body
      className={cn("portfolio-assets-details-card", {
        [`${className}`]: className,
        [`${_.snakeCase(title)}-card`]: title,
      })}
    >
      <div className="card-label">{title}</div>
      { quantity === 0 ? 
        <>
          <div className="card-is-empty">
            <hr/>
            <div>There are no {title} assets in your portfolio</div>
          </div>
        </>
      : (
        <>
          <div className="card-has-value">
            <div className="card-sub-label">RETAIL VALUE:</div>
            <div className="card-value">{formatCurrency(currentValue)}</div>
            <div className="card-sub-label">ACQUISITION COST:</div>
            <div className="card-cost">{formatCurrency(acquisitionCost)}</div>
            <div className="card-qty-ounces">
              <span>Quantity: {quantity}</span>
              <span>Ounces: {ounces}</span>
            </div>
          </div>
        </>
      )}
    </Card>
  );
};

export default AssetsDetailsCard;
